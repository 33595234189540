<template>
  <a-form-model
    class="fenceWrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item
      :label="$t('hat.electronFence.name')"
      prop="geofenceName"
    >
      <a-input
        :placeholder="$t('hat.electronFence.limitCharLen', { value: 32 })"
        :maxLength="32"
        v-model="form.geofenceName"
      />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('hat.electronFence.available')"
      prop="groupType"
    >
      <a-radio-group v-model="form.groupType" name="groupType">
        <a-radio :value="1">{{ $t('hat.electronFence.byDevice') }}</a-radio>
        <a-radio :value="2">{{ $t('hat.electronFence.byGroup') }}</a-radio>
        <a-radio :value="3">按项目</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label=" " prop="equip" v-if="form.groupType === 1">
      <a-select
        mode="multiple"
        v-model="form.equip"
        :placeholder="$t('hat.electronFence.selectDevice')"
        show-search
        option-filter-prop="children"
        :filter-option="true"
      >
        <a-select-option
          :value="item.deviceId"
          v-for="item in deviceList"
          :key="item.deviceId"
          >{{
            item.userName
              ? `${item.deviceName}(${item.userName})`
              : item.deviceName
          }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item label=" " prop="group" v-else-if="form.groupType === 2">
      <a-select
        mode="multiple"
        v-model="form.group"
        :placeholder="$t('hat.electronFence.selectGroup')"
      >
        <a-select-option
          :value="item.pkId"
          v-for="item in groupList"
          :key="item.pkId"
          >{{ item.deviceGroupName }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item label=" " prop="project" v-else>
      <project-select v-model="form.project" />
    </a-form-model-item>
    <div>
      <a-checkbox v-model="form.isDefault" />
      <span :class="$style.tipText"
        >项目下的设备的默认电子围栏为空时，使用当前作为默认（推荐）</span
      >
    </div>
    <!-- 由于项目列表接口有变动,关联地址先不显示 -->
    <!-- <a-form-model-item
      v-if="isNew"
      :label="$t('hat.electronFence.associatedAddress')"
      prop="projectId"
      :extra="$t('hat.electronFence.asDesc')"
    >
      <a-select
        show-search
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        v-model="form.projectId"
        :placeholder="$t('electricBox.select.projects')"
        @search="handleAddressSearch"
        @change="handleProjectChange"
        option-filter-prop="children"
      >
        <a-select-option
          :value="item.pkId"
          v-for="item in projects"
          :key="item.pkId"
          >{{ item.projectName }}</a-select-option
        >
      </a-select>
    </a-form-model-item> -->
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getMyDevice } from '@/services/smart-hat/device';
import { getProjectListPage } from '@/services/smart-hat/device-management';
import { getPageByName } from '@/services/smart-hat/group';
import { crossStorageModule } from '@/enum/store.js';
import ProjectSelect from '@/views/hat/components/project-select';

@Component({
  components: {
    ProjectSelect,
  },
})
export default class FenceForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  isNew = true;
  form = {
    geofenceName: '',
    geofenceId: '',
    groupType: 1,
    projectId: undefined,
    equip: [],
    group: [],
    project: undefined,
    isDefault: false,
  };
  rules = {
    geofenceName: [
      {
        required: true,
        message: this.$t('hat.electronFence.required', {
          value: this.$t('hat.electronFence.name'),
        }),
      },
    ],
  };

  deviceList = [];
  async getMyDevice() {
    const data = {
      current: 1,
      size: 500,
    };
    const { records } = await getMyDevice(data);
    this.deviceList = records;
  }

  @crossStorageModule.State('tenant') tenant;
  projects = [];
  async getProject() {
    const { records } = await getProjectListPage({
      idxTenantId: this.tenant.pkId,
      current: 1,
      size: 500,
    });
    this.projects = records;
  }
  async handleAddressSearch(search) {
    const { records } = await getProjectListPage({
      idxTenantId: this.tenant.pkId,
      current: 1,
      size: 500,
      keyword: search,
    });
    this.projects = records;
  }
  center = {
    lng: '',
    lat: '',
  };
  handleProjectChange(id) {
    this.form.projectId = id;
    const item = this.projects.find(v => v.pkId === id);
    if (item) {
      this.center.lng = item.longitude;
      this.center.lat = item.latitude;
    }
  }

  groupList = [];
  async getGroupList() {
    const data = {
      current: 1,
      size: 200,
    };
    const { records } = await getPageByName(data);
    this.groupList = records;
  }

  mounted() {
    Promise.all([this.getMyDevice(), this.getProject(), this.getGroupList()]);
    this.isNew = !(this.editData && Object.keys(this.editData).length > 0);
    if (!this.isNew) {
      this.form.projectId = this.editData.projectId;
      this.form.geofenceId = this.editData.geofenceId;
      this.form.geofenceName = this.editData.geofenceName;
      this.form.isDefault = this.editData.isDefault;
      const permission = this.editData.permission;
      this.form.groupType = permission.project ? 3 : permission.equip ? 1 : 2;
      this.form.equip = permission.equip || [];
      this.form.group = permission.group || [];
      this.form.project =
        (permission.project && permission.project[0]) || undefined;
    }
  }
  resolve = null;
  reject = null;
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const params = {
              geofenceId: this.form.geofenceId,
              geofenceName: this.form.geofenceName,
              permission: {},
              projectId: this.form.projectId,
              isDefault: this.form.isDefault,
            };
            if (this.center.lat) {
              params['point'] = this.center;
            }
            if (this.form.groupType === 1) {
              params.permission.equip = this.form.equip;
            } else if (this.form.groupType === 2) {
              params.permission.group = this.form.group;
            } else {
              params.permission.project = [this.form.project];
            }
            resolve(params);
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }

  formatTrim(value = '') {
    return value.trim();
  }
}
</script>

<style lang="less" module>
.tipText {
  color: #999999;
  font-size: 13px;
  margin-left: 4px;
}
</style>
