<template>
  <a-form-model
    :class="$style.fenceWrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item :label="$t('hat.electronFence.name')" prop="name">
      <a-input placeholder="" v-model="form.name" />
    </a-form-model-item>

    <a-form-model-item :label="$t('electricBox.mg.table.type')" prop="type">
      <a-radio-group v-model="form.type" name="type" :disabled="typeDisabled">
        <a-radio value="circle">{{
          $t('hat.electronFence.circleArea')
        }}</a-radio>
        <a-radio value="polygon">{{
          $t('hat.electronFence.polygonArea')
        }}</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.electronFence.radius')"
      prop="radius"
      v-if="form.type === 'circle'"
    >
      <a-input-number
        :disabled="typeDisabled"
        placeholder=""
        v-model="form.radius"
        :default-value="100"
        style="width: 100%;"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.remark')"
      prop="desc"
    >
      <a-input placeholder="" v-model="form.desc" />
    </a-form-model-item>
    <section :class="$style.btnGroups">
      <a-button @click="close">{{ $t('msg.cancel') }}</a-button>
      <a-button type="primary" @click="handleSubmit">{{
        $t('msg.confirm')
      }}</a-button>
    </section>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class RegionForm extends Vue {
  @Prop({ type: Object }) detail;
  @Prop({ type: Function }) close;
  @Prop({ type: Function }) submit;
  form = {
    desc: '',
    name: '',
    type: 'circle',
    radius: 100,
  };

  @Watch('detail', { immediate: true })
  onDetailChange(val) {
    if (val && val.name) {
      this.form.desc = val.desc;
      this.form.name = val.name;
      this.form.type = val.type;
      this.form.radius = val.radius ?? 100;
    }
  }

  get typeDisabled() {
    if (this.detail && this.detail.uid) {
      return true;
    }
    return false;
  }

  rules = {
    name: [
      {
        required: true,
        message: this.$t('hat.electronFence.required', {
          value: this.$t('hat.electronFence.name'),
        }),
      },
    ],
  };

  async handleSubmit() {
    await this.$refs.form.validate();
    let obj = {
      ...this.form,
    };
    if (this.detail && this.detail.name) {
      obj['id'] = this.detail.id;
    }
    this.close();
    this.submit(obj);
  }
}
</script>
<style lang="less" module>
.fenceWrapper {
  padding: 20px 20px 0 20px;
  height: 365px;
  position: relative;
}
.btnGroups {
  position: absolute;
  right: 20px;
  bottom: 20px;
  margin-top: 20px;
  text-align: right;
  button + button {
    margin-left: 20px;
  }
}
</style>
