import request from '../request';

const serviceName = '/iot-smart-helmet/group';

/**
 * @name 根据编组名模糊搜索编组信息
 */
export function getPageByName(data) {
  return request(`${serviceName}/pageByName`, {
    method: 'GET',
    body: data,
  });
}
