<template>
  <div
    :class="$style.mapDiv"
    ref="mapDiv"
    :style="{
      width: $store.state.home.bigScreen ? '100vw' : '100%',
      height: $store.state.home.bigScreen ? '100vh' : '664px',
      position: $store.state.home.bigScreen ? 'fixed' : '',
      top: 0,
      left: 0,
      zIndex: 9999,
    }"
  >
    <div
      :class="$style.screen"
      v-if="hasLoading && showMap"
      :style="{
        position: $store.state.home.bigScreen ? 'fixed' : 'absolute',
        bottom: $store.state.home.bigScreen ? '20px' : '75px',
        right: $store.state.home.bigScreen ? '20px' : '20px',
      }"
    >
      <x-icon
        :type="
          $store.state.home.bigScreen
            ? 'tc-icon-fullscreen-exit'
            : 'tc-icon-fullscreen'
        "
        :class="$style.icon111"
        @click="handleScreen"
      />
    </div>
    <div
      v-if="hasLoading && showMap"
      :class="$style.mapContainer"
      id="container"
      :style="{
        width: $store.state.home.bigScreen ? '100vw' : '100%',
        height: $store.state.home.bigScreen ? '100vh' : '600px',
      }"
    ></div>

    <empty-content v-if="hasLoading && !showMap" :class="$style.mapContainer" />
    <div
      v-if="syncType === CHART_DIALOG_KEY.UPDATE"
      :class="$style.topPart"
      ref="topPart"
    >
      <section :class="$style.searchWrap">
        <span :class="$style.title">{{
          $t('hat.electronFence.centerMap')
        }}</span>
        <a-select
          :class="$style.search"
          show-search
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          :placeholder="$t('hat.electronFence.search')"
          @change="handleAddressSelect"
          @search="handleAddressSearch"
        >
          <a-select-option v-for="d in tips" :key="d.id" :value="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
        <span :class="$style.opt">
          <a-tooltip placement="top">
            <span slot="title">{{
              $t('hat.electronFence.networkLocation')
            }}</span>
            <a-icon type="environment" @click="handleNetworkLocation" />
          </a-tooltip>
        </span>
      </section>
      <a-tooltip placement="top">
        <template slot="title">
          <span>{{ $t('hat.electronFence.desc.active') }}</span>
        </template>
        <a-button
          icon="plus"
          :class="$style.addButton"
          @click="
            handleAddArea({
              dialogStyle: {
                left: '-130px',
              },
              state: 'active',
            })
          "
          >{{ $t('hat.electronFence.activeArea') }}</a-button
        >
      </a-tooltip>
      <a-tooltip placement="top">
        <template slot="title">
          <span>{{ $t('hat.electronFence.desc.forbid') }}</span>
        </template>
        <a-button
          icon="plus"
          :class="$style.addButton"
          @click="
            handleAddArea({
              dialogStyle: {
                left: '176px',
              },
              state: 'forbid',
            })
          "
          >{{ $t('hat.electronFence.forbiddenRegion') }}</a-button
        >
      </a-tooltip>
      <!-- <a-tooltip placement="top">
        <template slot="title">
          <span>{{ $t('hat.electronFence.desc.centerPoint') }}</span>
        </template>
        <a-button
          icon="plus"
          :class="$style.addButton"
          @click="handleSetCenter(false)"
          >{{ $t('hat.electronFence.centerMark') }}
        </a-button>
      </a-tooltip> -->
    </div>
    <section
      v-if="hasLoading && showMap"
      :class="[$style.listWrap, menuShow ? '' : $style.menuShow]"
    >
      <span :class="$style.mIconWrap" v-if="!menuShow">
        <a-icon :class="$style.mIcon" @click="handleMenu" type="menu-fold" />
      </span>
      <section v-else>
        <div :class="$style.header">
          <span>
            <a-icon
              :class="$style.hIcon"
              @click="handleMenu"
              type="menu-unfold"
            />
          </span>
          <span :class="[$style.hTxt, $style.txtWrap]">{{
            $t('hat.electronFence.areaEditing')
          }}</span>
        </div>
        <empty-content v-if="!zoneArray.length" label="" />
        <draggable
          v-else
          :class="$style.zoneWrap"
          :value="zoneArray"
          @input="handleSort"
          :handle="syncType === CHART_DIALOG_KEY.UPDATE ? '.mover' : ''"
          :animation="300"
        >
          <div
            :class="[
              $style.item,
              'mover',
              activeChartUid === item.uid ? $style.active : '',
            ]"
            v-for="(item, idx) in zoneArray"
            :key="item.uid"
            @click="handleChartEdit(item)"
          >
            <a-tooltip placement="left">
              <span slot="title">{{ item.name }}</span>
              <span :class="$style.txtWrap"
                ><span :class="[$style.circle, iconColor(item.state)]">
                  <a-icon
                    v-if="item.state === TYPE_STATE.active"
                    :class="$style.sIcon"
                    type="check"
                  />
                  <a-icon
                    v-else-if="item.state === TYPE_STATE.forbid"
                    :class="$style.sIcon"
                    type="minus"
                  />
                  <a-icon
                    v-else
                    :class="$style.sIcon"
                    type="environment"
                  /> </span
                >{{ item.name }}</span
              >
            </a-tooltip>
            <span
              :class="$style.iconWrap"
              v-if="syncType === CHART_DIALOG_KEY.UPDATE"
            >
              <x-icon
                v-if="item.target"
                :class="$style.icon"
                type="tc-icon-delete"
                @click.stop="handleChartDelete(item, idx)"
              />
              <a-icon
                v-if="item.state !== TYPE_STATE.center"
                :class="$style.icon"
                type="edit"
                @click.stop="handleChartInfoEdit(item)"
              />
              <x-icon :class="$style.icon" type="tc-icon-drag-handler"></x-icon>
            </span>
          </div>
        </draggable>
      </section>
    </section>

    <section
      v-if="syncType === CHART_DIALOG_KEY.UPDATE"
      :class="$style.buttonWrap"
      :style="{
        position: $store.state.home.bigScreen ? 'fixed' : '',
        bottom: $store.state.home.bigScreen ? '20px' : '',
        right: $store.state.home.bigScreen ? '60px' : '',
      }"
    >
      <a-button :class="$style.btn" @click="$emit('finally')">{{
        $t('msg.cancel')
      }}</a-button>
      <a-button :class="$style.btn" type="primary" @click="handleSubmit">{{
        $t('msg.save')
      }}</a-button>
    </section>
    <section
      v-if="syncType === CHART_DIALOG_KEY.READ && $p.action('UPDATE')"
      :class="$style.buttonWrap"
      :style="{
        position: $store.state.home.bigScreen ? 'fixed' : '',
        bottom: $store.state.home.bigScreen ? '20px' : '',
        right: $store.state.home.bigScreen ? '60px' : '',
      }"
    >
      <a-button :class="$style.btn" type="primary" @click="handleEdit">{{
        $t('hat.deviceManagement.edit')
      }}</a-button>
    </section>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import {
  funAMap,
  TYPE_CHART,
  TYPE_STATE,
  ActiveColor,
  ForbidColor,
  drawCharts,
  formatToApi,
  formatToData,
  mouseEvent,
  CHART_DIALOG_KEY,
} from './util';
import FormRegion from './form-region';
import {
  getZonesData,
  deleteZone,
  getFenceDetail,
  updateFence,
  batchZone,
} from '@/services/smart-hat/geofence';
import { createModal } from '@triascloud/x-components';
import { delay, uuid } from '@triascloud/utils';
import EmptyContent from '@/components/empty-content';
import { getPointsCenter } from '@/views/hat/utils';
import * as turf from '@turf/turf';

/** @description 获取新图形的zIndex */
const nextzIndex = (list = []) => {
  const len = list.length;
  return 51 + len;
};

/** @description 改变图形的颜色 */
const changeGraphColor = (state, graph) => {
  if (state === TYPE_STATE.active) {
    graph.setOptions({
      fillColor: ActiveColor.fill,
      strokeColor: ActiveColor.stroke,
    });
  } else {
    graph.setOptions({
      fillColor: ForbidColor.fill,
      strokeColor: ForbidColor.stroke,
    });
  }
};

@Component({
  components: {
    EmptyContent,
    Draggable,
  },
})
export default class FenceMap extends Vue {
  @Prop({ type: String }) id;
  @Prop({ type: String }) type;
  @Prop({ type: Object }) permission;

  /** @name 每种类型图层的icon颜色 */
  iconColor(state) {
    if (state === TYPE_STATE.active) {
      return this.$style.active;
    } else if (state === TYPE_STATE.forbid) {
      return this.$style.forbid;
    } else {
      return this.$style.center;
    }
  }

  TYPE_STATE = TYPE_STATE;

  syncType = '';
  hasLoading = false;
  showMap = false;
  created() {
    this.$store.commit('home/setBigScreen', false);
  }
  async mounted() {
    this.syncType = this.type;
    // await Promise.all([this.getFenceDetail(), this.getZoneList()]);
    await this.getZoneList();
    this.hasLoading = true;

    if (this.zoneArray.length || this.syncType === CHART_DIALOG_KEY.UPDATE) {
      this.showMap = true;
      await this.addChartEditor();
    }
  }

  CHART_DIALOG_KEY = CHART_DIALOG_KEY;

  async handleEdit() {
    this.syncType = CHART_DIALOG_KEY.UPDATE;
    this.showMap = true;
    if (!this.zoneArray.length) {
      await this.addChartEditor();
    }
    if (this.chartCache.length) {
      this.chartCache.forEach(chart => {
        this.clickEvent(this.AMap, chart);
        chart.clearEvents('mouseover');
        chart.clearEvents('mouseout');
      });
    }
  }

  async handleSubmit() {
    if (this.circleEditor._ready_) {
      this.$message.warning(this.$t('hat.electronFence.graphTip'));
      return;
    }
    if (this.polygonEditor._ready_) {
      this.$message.warning(this.$t('hat.electronFence.graphTip'));
      return;
    }
    this.editorClose();
    // 等待所有图形关闭
    await delay(50);
    if (this.centerMarker) {
      const extData = this.centerMarker.getExtData();
      const fIndex = this.zoneArray.findIndex(v => v.uid === extData._uid_);
      if (fIndex !== -1) {
        this.zoneArray.splice(fIndex, 1);
      }
    }
    const obj = {
      array: formatToData(this.zoneArray, this.id),
      position: {},
    };
    // if (this.center && this.center.lng) {
    //   obj.position = {
    //     lng: this.center.lng,
    //     lat: this.center.lat,
    //     zIndex: this.centerMarker._opts.zIndex,
    //   };
    // }
    // 以第一个电子围栏的中心作为中心点
    if (obj.array.length) {
      if (obj.array[0].zone['0']) {
        // 圆形围栏
        const { point } = obj.array[0].zone['0'];
        obj.position = {
          lng: point.lng,
          lat: point.lat,
          zIndex: 50,
        };
      } else {
        // 多边形围栏
        const point = getPointsCenter(obj.array[0].zone['1']);
        obj.position = {
          lng: point[1],
          lat: point[0],
          zIndex: 50,
        };
      }
    }
    const [fence, mapRes] = await Promise.all([
      updateFence({
        point: obj.position,
        geofenceId: this.id,
        permission: this.permission,
      }),
      batchZone(obj.array),
    ]);
    if (mapRes.length && fence) {
      this.$message.success(
        this.$t('hat.electronFence.updateTip', {
          value: this.$t('hat.electronFence.success'),
        }),
      );
    } else {
      // this.$message.warn(
      //   this.$t('hat.electronFence.updateTip', {
      //     value: this.$t('hat.electronFence.fail'),
      //   }),
      // );
    }
    this.$emit('finally');
  }

  map = null;
  clickedMap = null;
  polygonEditor = null;
  circleEditor = null;
  placeSearch = null;

  centerzIndex = -1;
  async getFenceDetail() {
    const data = await getFenceDetail(this.id);
    if (data.point) {
      this.center = {
        lng: data.point.lng,
        lat: data.point.lat,
      };
      this.centerzIndex = data.point.zIndex;
    }
  }

  zoneArray = [];
  async getZoneList() {
    const array = await getZonesData({
      geofenceId: this.id,
    });
    this.zoneArray = formatToApi(array);
    array.sort((a, b) => b.settings.zIndex - a.settings.zIndex);
    // 以电子围栏的中心作为中心点
    if (array.length) {
      if (array[0].zone['0']) {
        // 圆形围栏
        const { point } = array[0].zone['0'];
        this.center = {
          lng: point.lng,
          lat: point.lat,
        };
      } else {
        // 多边形围栏
        const point = getPointsCenter(array[0].zone['1']);
        this.center = {
          lng: point[1],
          lat: point[0],
        };
      }
    }
  }

  handleNetworkLocation() {
    const geolocation = new this.AMap.Geolocation({
      enableHighAccuracy: true,
      timeout: 10000,
      offset: [10, 20],
      zoomToAccuracy: true,
      position: 'RB',
    });
    geolocation.getCurrentPosition((status, result) => {
      if (status == 'complete') {
        const pos = result.position;
        this.map.setZoomAndCenter(16, [pos.lng, pos.lat]);
        this.$message.success(this.$t('hat.electronFence.locationSuccess'));
      }
    });
  }

  autoComplete = undefined;
  tips = [];
  handleAddressSearch(address) {
    this.autoComplete.search(address, (status, val) => {
      if (status === 'complete') {
        this.tips = val.tips;
      }
    });
  }
  addressId = null;
  handleAddressSelect(id) {
    const result = this.tips.find(v => v.id === id);
    if (result) {
      const pos = result.location;
      this.map.setCenter([pos.lng, pos.lat]);
    }
  }

  AMap = undefined;
  chartCache = [];
  async addChartEditor() {
    const AMap = await funAMap({
      plugins: [
        'AMap.PolygonEditor',
        'AMap.CircleEditor',
        'AMap.AutoComplete',
        'AMap.Geolocation',
      ],
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    const mapOption = {
      zoom: 16,
      doubleClickZoom: false,
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    };
    if (this.center && this.center.lng) {
      mapOption['center'] = [this.center.lng, this.center.lat];
    }
    this.map = new AMap.Map('container', mapOption);
    // if (this.center && this.center.lng) {
    //   this.handleSetCenter(true);
    // }

    const autocomplete = new AMap.AutoComplete();
    this.autoComplete = autocomplete;

    this.polygonEditor = new AMap.PolygonEditor(this.map, null, {
      createOptions: {
        fillColor: ActiveColor.fill,
        strokeColor: ActiveColor.stroke,
      },
      editOptions: {
        fillColor: '#e2e6f5',
        strokeWeight: 3,
        strokeColor: '#1791fc',
      },
    });
    this.circleEditor = new AMap.CircleEditor(this.map, null, {
      editOptions: {
        fillColor: '#e2e6f5',
        strokeWeight: 3,
        strokeColor: '#1791fc',
      },
    });

    this.polygonEditor.on('add', e => {
      this.map.setDefaultCursor('default');
      this.polygonEditor._ready_ = false;
      const chart = e.target;
      chart.setOptions({
        zIndex: this.polygonEditor._zIndex_,
      });
      const extData = {
        _state_: this.polygonEditor._state_,
        _name_: this.polygonEditor._name_,
        _desc_: this.polygonEditor._desc_,
        _uid_: this.polygonEditor._uid_,
      };
      chart.extData = extData;
      chart.setExtData(extData);
      this.eventCallback(AMap, chart);
    });

    this.polygonEditor.on('removenode', async e => {
      if (e.target._opts.path.length === 0) {
        const uid = e.target._opts.extData._uid_
          ? e.target._opts.extData._uid_
          : e.target.extData._uid_;
        const fIndex = this.zoneArray.findIndex(v => v.uid === uid);
        if (fIndex !== -1) {
          this.zoneArray.splice(fIndex, 1);
        }
        let cFIndex = -1;
        this.chartCache.forEach((v, i) => {
          const chartUid = v._opts.extData._uid_
            ? v._opts.extData._uid_
            : v.extData._uid_;
          if (chartUid === uid) {
            cFIndex = i;
          }
        });
        if (cFIndex !== -1) {
          this.chartCache.splice(cFIndex, 1);
        }
        const id = e.target._opts.extData._id_
          ? e.target._opts.extData._id_
          : e.target.extData._id_;
        if (id) {
          await deleteZone(id);
        }
      }
    });

    // end 事件在 editor 调用 close ，后触发
    this.polygonEditor.on('end', e => {
      if (e.target._opts.path.length === 0) {
        this.activeChart = {};
        return;
      }
      if (this.polygonEditor.delete) {
        this.polygonEditor.delete = false;
        return;
      }
      const res = e.target;
      const uid = res._opts.extData._uid_
        ? res._opts.extData._uid_
        : res.extData._uid_;
      const fObj = this.zoneArray.find(v => v.uid === uid);
      if (fObj) {
        if (
          this.currentForm &&
          this.currentForm.uid &&
          this.currentForm.uid === uid
        ) {
          fObj.path = res._opts.path;
          fObj.state = this.currentForm.state;
          fObj.name = this.currentForm.name;
          fObj.desc = this.currentForm.desc;
          if (!fObj.target) {
            fObj.target = res;
          }
          res.extData._state_ = this.currentForm.state;
          res.extData._name_ = this.currentForm.name;
          res.extData._desc_ = this.currentForm.desc;
          this.currentForm = {};
          const cacheResult = this.chartCache.find(item => {
            const cUid = item._opts.extData._uid_
              ? item._opts.extData._uid_
              : item.extData._uid_;
            if (cUid === uid) {
              return item;
            } else {
              return null;
            }
          });
          if (!cacheResult) {
            this.chartCache.push(res);
          }
        } else {
          if (!fObj.target) {
            fObj.target = res;
            fObj.path = res._opts.path;
            changeGraphColor(fObj.state, res);
          }
        }
      }
    });

    this.circleEditor.on('add', e => {
      this.map.setDefaultCursor('default');
      this.circleEditor._ready_ = false;
      const chart = e.target;
      chart.setOptions({
        zIndex: this.circleEditor._zIndex_,
      });
      const extData = {
        _state_: this.circleEditor._state_,
        _name_: this.circleEditor._name_,
        _desc_: this.circleEditor._desc_,
        _uid_: this.circleEditor._uid_,
      };
      chart.extData = extData;
      chart.setExtData(extData);
      this.eventCallback(AMap, chart);
    });

    // end 事件在 editor 调用 close ，后触发
    this.circleEditor.on('end', e => {
      if (this.circleEditor.delete) {
        this.circleEditor.delete = false;
        return;
      }
      const res = e.target;
      const uid = res._opts.extData._uid_
        ? res._opts.extData._uid_
        : res.extData._uid_;
      const fObj = this.zoneArray.find(v => v.uid === uid);
      if (fObj) {
        if (
          this.currentForm &&
          this.currentForm.uid &&
          this.currentForm.uid === uid
        ) {
          fObj.center = res._opts.center;
          fObj.radius = res._opts.radius;
          fObj.state = this.currentForm.state;
          fObj.name = this.currentForm.name;
          fObj.desc = this.currentForm.desc;
          if (!fObj.target) {
            fObj.target = res;
          }
          res.extData._state_ = this.currentForm.state;
          res.extData._name_ = this.currentForm.name;
          res.extData._desc_ = this.currentForm.desc;
          this.currentForm = {};
          const cacheResult = this.chartCache.find(item => {
            const cUid = item._opts.extData._uid_
              ? item._opts.extData._uid_
              : item.extData._uid_;
            if (cUid === uid) {
              return item;
            } else {
              return null;
            }
          });
          if (!cacheResult) {
            this.chartCache.push(res);
          }
        } else {
          if (!fObj.target) {
            fObj.target = res;
            fObj.center = res._opts.center;
            fObj.radius = res._opts.radius;
            changeGraphColor(fObj.state, res);
          }
        }
      }
    });

    if (this.zoneArray.length) {
      this.chartCache = drawCharts(this.zoneArray, AMap, this.eventCallback);
      this.map.add(this.chartCache);
    }
  }
  eventCallback(AMap, chart) {
    if (this.syncType === CHART_DIALOG_KEY.UPDATE) {
      this.clickEvent(AMap, chart);
    }
    if (this.syncType === CHART_DIALOG_KEY.READ) {
      mouseEvent(this.AMap, chart, this.map);
    }
  }
  activeChart = {};
  /** @description 为每个图形（polygon、circle）注册【点击事件】=》用于设置选中编辑状态 */
  clickEvent(AMap, chart) {
    chart.on('dblclick', event => {
      let editor = undefined;
      if (event.target instanceof AMap.Polygon) {
        editor = this.polygonEditor;
        editor._editing = editor.editable;
        // 当前【要】编辑图形器为PolygonEditor，而CircleEditor为【编辑状态】
        if (this.circleEditor._editing) {
          this.circleEditor.close();
        }
      } else if (event.target instanceof AMap.Circle) {
        editor = this.circleEditor;
        // 多边形绘制中，不开启圆形编辑
        if (!this.polygonEditor.getTarget() && this.polygonEditor.drawable) {
          return false;
        }
        // 当前【要】编辑图形器为CircleEditor，而PolygonEditor为【编辑状态】
        if (this.polygonEditor.editable) {
          this.polygonEditor.close();
        }
      }
      // 【选中状态】当选中的图形是同种类型的图形，不是同一个图形
      if (
        this.activeChart !== event.target &&
        editor._editing &&
        ((event.target instanceof AMap.Polygon &&
          this.activeChart instanceof AMap.Polygon) ||
          (event.target instanceof AMap.Circle &&
            this.activeChart instanceof AMap.Circle))
      ) {
        editor._editing = !editor._editing;
      }

      if (!editor._editing) {
        editor.setTarget(event.target);
        editor.open();
        this.activeChart = event.target;
        if (!event.target.extData) {
          event.target.extData =
            event.target._opts && event.target._opts.extData;
        }
        const data = event.target.extData;
        this.currentForm = {
          uid: data._uid_,
          desc: data._desc_,
          name: data._name_,
          state: data._state_,
          id: data._id_, // 编辑
        };
      } else {
        editor.close();
        this.activeChart = {};
      }
      const uid = event.target._opts.extData._uid_
        ? event.target._opts.extData._uid_
        : event.target.extData._uid_;
      const fObj = this.zoneArray.find(v => v.uid === uid);
      // 保证重复双击导致图形的zIndex不会变为默认值
      event.target.setOptions({
        zIndex: fObj.zIndex,
      });
    });
  }

  centerMarker = undefined;
  center = undefined;
  handleSetCenter(position = false) {
    if (!position) {
      this.center = this.map.getCenter();
    }
    if (!this.centerMarker) {
      this.centerMarker = this.createMarker([this.center.lng, this.center.lat]);
      let zIndex = -1;
      if (this.centerzIndex && this.centerzIndex !== -1) {
        zIndex = this.centerzIndex;
      } else {
        zIndex = nextzIndex(this.zoneArray);
      }
      this.centerMarker.setOptions({
        zIndex,
      });
      const extData = this.centerMarker.getExtData();
      this.zoneArray.unshift({
        target: this.centerMarker,
        type: TYPE_CHART.center,
        state: TYPE_STATE.center,
        name: extData._name_,
        desc: extData._desc_,
        uid: extData._uid_,
        id: extData._id_,
        zIndex,
      });
      const list = this.zoneArray;
      list.sort((a, b) => b.zIndex - a.zIndex);
      this.zoneArray = list;
      this.chartCache.push(this.centerMarker);
    } else {
      this.centerMarker.setPosition([this.center.lng, this.center.lat]);
    }
    this.centerMarker.on('dragend', e => {
      this.center = {
        lng: e.lnglat.lng,
        lat: e.lnglat.lat,
      };
    });
    if (!this.centerMarker.getMap()) {
      this.map.add(this.centerMarker);
    }
  }

  createMarker(position) {
    return new this.AMap.Marker({
      position,
      draggable: true,
      zIndex: 8,
      extData: {
        _state_: TYPE_STATE.center,
        _desc_: '',
        _name_: '中心点',
        _id_: null,
        _uid_: 'centerMarker',
      },
    });
  }

  currentForm = {};
  modal = null;
  async handleAddArea({ dialogStyle, state, detail }) {
    if (this.polygonEditor._ready_) {
      this.$message.warning(this.$t('hat.electronFence.addFence'));
      return;
    }
    if (this.circleEditor._ready_) {
      this.$message.warning(this.$t('hat.electronFence.addFence'));
      return;
    }
    const modal = createModal(
      () => (
        <FormRegion
          detail={detail}
          close={() => {
            modal.handleClosed();
          }}
          submit={result => {
            this.areaOkHandle(result, state, detail);
          }}
        />
      ),
      {
        width: 298,
        mask: false,
        getContainer: () => this.$refs.topPart,
        dialogStyle: {
          top: '4px',
          ...dialogStyle,
        },
        wrapClassName: this.$style.areaWrap,
      },
    );
    this.modal = modal;
  }
  areaOkHandle(result, state, detail) {
    this.map.setDefaultCursor('crosshair');
    const editor = this.chartStyle(state, result);
    // 新增
    if (!detail) {
      const uid = this.addArea({ ...result, state }, editor);

      // 业务数据
      this.currentForm = {
        ...result,
        state,
        uid,
      };

      this.editorClose();
      editor.setTarget();
      editor.open();
      editor._ready_ = true;
    } else {
      const fObj = this.zoneArray.find(v => v.uid === detail.uid);
      if (fObj) {
        fObj.name = result.name;
        fObj.desc = result.desc;
      }
      if (editor instanceof this.AMap.CircleEditor) {
        if (!editor._editing) {
          editor.setTarget(detail.target);
          editor.open();
        }
      } else if (editor instanceof this.AMap.PolygonEditor) {
        if (!editor.editable) {
          editor.setTarget(detail.target);
          editor.open();
        }
      }
      this.activeChart = detail.target;
      // 业务数据
      this.currentForm = {
        ...result,
        state,
        uid: detail.uid,
      };
    }
  }
  /** @description 更改图形的样式 */
  chartStyle(state, result) {
    let editorOpt = null;
    let editor = null;
    if (result.type === TYPE_CHART.circle) {
      editorOpt = this.circleEditor._normalCircleStyle;
      editorOpt.radius = result.radius ?? editorOpt.radius;
      editor = this.circleEditor;
    } else {
      editorOpt = this.polygonEditor._opt.createOptions;
      editor = this.polygonEditor;
    }
    // 业务数据
    editor._name_ = result.name;
    editor._desc_ = result.desc;
    editor._state_ = state;
    // 编辑
    if (result.id) {
      editor._id_ = result.id;
    }

    // 基础样式
    editorOpt.strokeStyle = 'solid';
    editorOpt.strokeOpacity = 1;
    editorOpt.strokeWeight = 3;

    if (state === TYPE_STATE.forbid) {
      editor._state_ = TYPE_STATE.forbid;
      editorOpt.fillColor = ForbidColor.fill;
      editorOpt.strokeColor = ForbidColor.stroke;
    } else {
      editor._state_ = TYPE_STATE.active;
      editorOpt.fillColor = ActiveColor.fill;
      editorOpt.strokeColor = ActiveColor.stroke;
    }
    return editor;
  }
  /** @description 只有一个【编辑状态】的【图形】，【关闭】所有图形编辑器 */
  editorClose() {
    if (this.circleEditor._editing) {
      this.circleEditor.close();
    }
    if (this.polygonEditor.editable) {
      this.polygonEditor.close();
    }
  }
  addArea(result, editor) {
    const zIndex = nextzIndex(this.zoneArray);
    const uid = uuid();
    const opt = {
      target: null,
      type: undefined,
      state: result.state,
      name: result.name,
      desc: result.desc,
      id: null,
      uid,
      zIndex,
    };
    editor._uid_ = uid;
    editor._zIndex_ = zIndex;
    if (result.type === TYPE_CHART.circle) {
      opt.type = TYPE_CHART.circle;
      opt['center'] = [];
      opt['radius'] = 0;
    } else {
      opt.type = TYPE_CHART.polygon;
      opt['path'] = [];
    }
    this.zoneArray.unshift(opt);
    this.activeChart = {
      extData: {
        _uid_: uid,
      },
    };

    return uid;
  }

  get activeChartUid() {
    let uid = '-1';
    if (this.activeChart?._opts?.extData?._uid_) {
      uid = this.activeChart?._opts?.extData?._uid_;
    }
    if (this.activeChart?.extData?._uid_) {
      uid = this.activeChart?.extData?._uid_;
    }
    return uid;
  }

  menuShow = true;
  handleMenu() {
    this.menuShow = !this.menuShow;
  }
  handleSort(list) {
    const len = list.length;

    list.forEach((item, index) => {
      item.zIndex = 50 + len - index;
    });

    this.chartCache.forEach(item => {
      const uid = item._opts.extData._uid_
        ? item._opts.extData._uid_
        : item.extData._uid_;
      const fObj = this.zoneArray.find(v => v.uid === uid);
      if (fObj) {
        item.setOptions({
          zIndex: fObj.zIndex,
        });
        const _state_ = item._opts.extData._state_
          ? item._opts.extData._state_
          : item.extData._state_;
        if (_state_ === TYPE_STATE.center) {
          this.centerMarker.setOptions({
            zIndex: fObj.zIndex,
          });
        }
      }
    });

    this.zoneArray = list;
  }
  handleChartInfoEdit(item) {
    this.handleChartEdit(item);
    if (this.modal) {
      this.modal.handleClosed();
    }
    this.handleAddArea({
      dialogStyle: {
        left: item.state === TYPE_STATE.active ? '-130px' : '176px',
      },
      state: item.state,
      detail: {
        desc: item.desc,
        name: item.name,
        uid: item.uid,
        id: item.id, // 编辑
        type: item.type,
        radius: item.radius ? item.radius : '',
        target: item.target,
      },
    });
  }
  handleChartEdit(item) {
    this.activeChart = item.target;
    this.currentForm = {
      ...item,
    };
    if (this.syncType === CHART_DIALOG_KEY.READ) {
      if (item.type === TYPE_CHART.circle) {
        if (item.target._opts.center) {
          this.map.setCenter(item.target._opts.center);
        }
      } else if (item.type === TYPE_CHART.polygon) {
        if (item.target._opts.path && item.target._opts.path[0]) {
          const array = [...item.target._opts.path, item.target._opts.path[0]];
          const polygon = turf.polygon([array]);
          const centroid = turf.centroid(polygon);
          this.map.setCenter(centroid.geometry.coordinates);
        }
      } else {
        if (this.centerMarker._opts.position) {
          this.map.setCenter(this.centerMarker._opts.position);
        }
      }
      return;
    }
    this.editorClose();
    if (item.type === TYPE_CHART.circle) {
      this.circleEditor.close();
      this.circleEditor.setTarget(item.target);
      this.circleEditor.open();
      if (item.target._opts.center) {
        this.map.setCenter(item.target._opts.center);
      }
    } else if (item.type === TYPE_CHART.polygon) {
      this.polygonEditor.close();
      this.polygonEditor.setTarget(item.target);
      this.polygonEditor.open();
      if (item.target && item.target._opts.path && item.target._opts.path[0]) {
        const array = [...item.target._opts.path, item.target._opts.path[0]];
        const polygon = turf.polygon([array]);
        const centroid = turf.centroid(polygon);
        this.map.setCenter(centroid.geometry.coordinates);
      }
    } else {
      if (this.centerMarker._opts.position) {
        this.map.setCenter(this.centerMarker._opts.position);
      }
    }
  }
  async handleChartDelete(item, index) {
    let cFIndex = -1;
    this.chartCache.forEach((v, i) => {
      const chartUid = v._opts.extData._uid_
        ? v._opts.extData._uid_
        : v.extData._uid_;
      if (chartUid === item.uid) {
        cFIndex = i;
      }
    });
    if (item.type === TYPE_CHART.center) {
      this.zoneArray.splice(index, 1);
      if (cFIndex !== -1) {
        this.chartCache.splice(cFIndex, 1);
      }
      this.map.remove(this.centerMarker);
      this.centerMarker = undefined;
      this.center = undefined;
      return;
    }
    if (item.type === TYPE_CHART.circle) {
      this.circleEditor.delete = true;
      if (this.circleEditor._editing) {
        this.circleEditor.close();
      }
    } else {
      this.polygonEditor.delete = true;
      if (this.polygonEditor.editable) {
        this.polygonEditor.close();
      }
    }
    let result;
    if (item.id) {
      result = await deleteZone(item.id);
      if (result) {
        this.zoneArray.splice(index, 1);
        item.target && this.map.remove(item.target);
      }
    } else {
      this.zoneArray.splice(index, 1);
      item.target && this.map.remove(item.target);
      result = true;
    }
    if (cFIndex !== -1) {
      this.chartCache.splice(cFIndex, 1);
    }
    if (this.activeChart._opts) {
      if (
        item.target &&
        this.activeChart._opts.extData._uid_ === item.target._opts.extData._uid_
      ) {
        this.activeChart = {};
      }
    }
    if (result === true) {
      this.$message.success(
        this.$t('hat.electronFence.clear', {
          value: this.$t('hat.electronFence.success'),
        }),
      );
    } else {
      this.$message.success(
        this.$t('hat.electronFence.clear', {
          value: this.$t('hat.electronFence.fail'),
        }),
      );
    }
  }
  handleScreen() {
    const bigScreen = this.$store.state.home.bigScreen;
    this.$store.commit('home/setBigScreen', !bigScreen);
  }
}
</script>

<style lang="less" module>
.areaWrap {
  width: 798px;
  height: 450px;
  position: absolute !important;
  :global {
    .ant-modal-body {
      margin-bottom: 0;
    }
  }
}
.mapDiv {
  width: 100%;
  height: 664px;
  position: relative;
  overflow-x: hidden;
  .screen {
    position: absolute;
    bottom: 75px;
    right: 20px;
    z-index: 99;
  }
  .icon111 {
    font-size: 30px;
    color: var(--primary);
  }
  .topPart {
    position: absolute;
    z-index: 9999;
    top: 10px;
    left: 10px;
    display: flex;

    .searchWrap {
      display: inline-flex;
      align-items: center;
      height: 36px;
      background-color: var(--block-bg);
      color: var(--font);
      border-radius: 4px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      .title {
        width: 76px;
        padding-left: 10px;
        font-weight: 600;
        line-height: 36px;
      }
      .search {
        width: 184px;
        :global {
          .ant-select-selection__rendered {
            line-height: 32px;
          }
          .ant-select-selection {
            border: none;
            box-shadow: none;
          }
        }
      }
      .opt {
        width: 36px;
        text-align: center;
      }
    }

    .addButton {
      margin-left: 10px;
      background: var(--block-bg);
      color: var(--font);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      border-color: transparent;
      font-weight: 600;
    }

    .centerPoint {
      width: 290px;
      color: var(--font);

      :global {
        .ant-input-group {
          margin-top: 2px;
        }

        .ant-input {
          background: var(--block-bg);
        }

        .ant-input-group-addon {
          background: var(--block-bg);
        }
      }
    }
  }

  .mapContainer {
    height: 600px;
  }

  .buttonWrap {
    text-align: right;
    padding: 20px 10px 4px 10px;
    .btn + .btn {
      margin-left: 20px;
    }
  }
}

:global {
  .ant-popover-message {
    .ant-popover-message-title {
      padding-left: 0;
    }

    .anticon-exclamation-circle {
      display: none;
    }
  }
}
.listWrap {
  position: absolute;
  z-index: 9999;
  padding: 6px 0;
  width: 216px;
  height: 349px;
  overflow: hidden;
  padding: 8px 10px 10px 10px;
  top: 10px;
  right: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--block-bg);
  border-radius: 4px;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;

  &.menuShow {
    transform: translateX(0);
    height: 32px !important;
    width: 32px;
    .zoneWrap {
      height: 0;
    }
  }
  .mIconWrap {
    width: 14px;
    height: 14px;
    display: inline-flex;
    .mIcon {
      font-size: 16px;
      color: var(--font);
      cursor: pointer;
    }
  }

  .zoneWrap {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .hIcon {
      font-size: 16px;
      color: var(--font);
      cursor: pointer;
    }
    .hTxt {
      font-size: 14px;
      width: auto;
      color: var(--font);
    }
  }
  .item {
    padding: 0 10px;
    height: 36px;
    font-weight: 600;
    width: 196px;
    color: var(--font);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(218, 218, 218, 0.2);
    border-radius: 4px;
    .circle {
      width: 14px;
      height: 14px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 4px;
      &.forbid {
        background-color: #ff4747;
      }
      &.active {
        background-color: #4771ff;
      }

      &.center {
        background-color: var(--font);
      }
      .sIcon {
        color: #ffffff;
        font-size: 10px;
      }
    }
    &.active {
      color: var(--font-active);
      color: #ffffff;
      background-color: var(--primary-50);
      .icon {
        color: #ffffff;
      }
    }
  }
  .item + .item {
    margin-top: 8px;
  }
  .txtWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
  }
  .iconWrap {
    .icon {
      color: var(--font-sub);
    }
    .icon + .icon {
      margin-left: 10px;
    }
  }
}
</style>
